import { useEffect, useRef } from 'react';
import React from 'react';
import { motion } from 'framer-motion';

import { useLocation } from '@reach/router';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import { BikesCalculator } from '../../components/Calculators/bikes-calculator';
import { ScContainer } from '../../components/container/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { scrollToRef } from '../../utils/scroll';

import {
	ScBikesCalculatorSection,
	ScEmployeeCyclingCalculatorContainer,
	ScEmployeeCyclingCalculatorWrapper,
} from './styled';

export const BikeCalculatorSection = ({
	title,
	isItEmployee,
}: {
	title?: string;
	isItEmployee: boolean;
}) => {
	const { ref, controls } = useContentAnimation();
	const calculator = useRef<HTMLInputElement | null>(null);
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('section')) {
			scrollToRef(calculator);
		}
		if (location.hash.startsWith('#savings-calculator')) {
			scrollToRef(calculator);
		}
	}, [location]);

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScBikesCalculatorSection
				ref={ref}
				initial="hidden"
				animate={controls}
				variants={animationContainer}
			>
				<ScEmployeeCyclingCalculatorContainer>
					<Top />
					<ScEmployeeCyclingCalculatorWrapper>
						<ScContainer ref={calculator}>
							<MotionDiv variants={fadeInUp}>
								<ScTitle ref={ref}>{title || 'See your savings'}</ScTitle>
							</MotionDiv>
							<BikesCalculator isItEmployee={isItEmployee} />
						</ScContainer>
					</ScEmployeeCyclingCalculatorWrapper>
					<Bot />
				</ScEmployeeCyclingCalculatorContainer>
			</ScBikesCalculatorSection>
		</motion.section>
	);
};
